<template>
  <div class="system-user-container">
    <base-table
      ref="refTable"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="72vh"
      @des-edit-success="handleDesEditSuccess"
      @header-action="handleHeaderAction"
    >
      <template #refInfo="{ model }">
        <el-form-item
          :label="t('关联信息')"
          prop="ref_id"
          :rules="{
            required: true,
            message: t('请选择关联信息'),
            trigger: 'blur',
          }"
        >
          <jwt-select-server
            v-model="model.ref_id"
            class="wi"
            :request="request"
            v-bind="{
              clearable: false,
              filterable: true,
              listApi: `/${model.type}/index`,
              searchKey: 'title',
              placeholder: t('请选择关联信息'),
              focusFetch: true,
            }"
          />
        </el-form-item>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { defineComponent, ref } from 'vue'
  import { DATETIME_FORMAT, FEEDBACK_TYPE } from '@/config/variable.config'
  import { customerFeedbackAddDialog } from '@/utils/dialogBoxConfig'
  import { customerFeedbackSearchConfig } from '@/utils/headerSearchConfig'
  // const MODULE = 'quote' // 模块
  const MODULE = 'customer-complain' // 模块
  import { t } from '@/utils/i18n'

  import request from '@/utils/request'

  export default defineComponent({
    name: 'CustomerFeedbackList',
    components: {},
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      const quoteDetail = ref(null)
      const formModel = ref(null)
      const QuoteHistoryDrawerRef = ref(null)
      const refTable = ref(null)

      // 表格数据
      const tableColumns = [
        { label: 'ID', prop: 'id', width: 85, sortable: 'custome' },
        // {
        //   label: '单号',
        //   prop: 'code',
        //   width: 200,
        //   class: 'cp cb tdu',
        //   publicDrawerConfig: {
        //     openName: 'quoteDetail',
        //     info: props?.info,
        //     params: props?.params,
        //   },
        // },
        {
          label: '客户',
          prop: 'customer_info.title',
          width: 200,
          class: 'cp cb tdu',
          publicDrawerConfig: {
            openName: 'customerDetail',
            api: {
              searchKey: 'id',
              valueKey: 'customer_id',
            },
          },
        },
        {
          label: '类型',
          prop: 'type',
          width: 150,
          valFun: (row) => {
            return FEEDBACK_TYPE[row.type]
          },
        },
        {
          label: '关联信息',
          prop: 'product_info',
          width: 190,
          valFun: (row) => {
            if (row.product_info) {
              return row.product_info?.title || '-'
            } else if (row.sale_order_info) {
              return row.sale_order_info?.title || '-'
            } else if (row.supplier_info) {
              return row.supplier_info?.title || '-'
            }
          },
        },
        { label: '原因', prop: 'reason', minWidth: 150 },

        {
          label: '创建时间',
          prop: 'create_time',
          width: 150,
          dateTimeFormat: DATETIME_FORMAT,
        },
        {
          width: 150,
           label: '操作',
            isOperate: true,
          fixed: 'right',
          action: {
            edit: {
              type: 'editBox',
            },
            list: ['delete'],
          },
        },
      ]

      const tableConfig = {
        headerActions: {
          add: {
            type: 'editBox',
          },
          list: ['add'],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props?.params?.searchKey || '']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: props?.params?.from
          ? null
          : customerFeedbackSearchConfig(),
        editBox: customerFeedbackAddDialog(
          MODULE,
          props?.params?.from,
          props?.info
        ),
      }

      const handleDesEditSuccess = () => {
        refTable.value.jwtTableRef?.dynamicsTabsInstance?.tableRef?.initSearch()
      }

      const handleHeaderAction = (action, selectionRow, drawerInfo) => {
        console.log('action :>> ', action)
        console.log('selectionRow :>> ', selectionRow)
        console.log('drawerInfo :>> ', drawerInfo)
      }

      return {
        tableConfig,
        quoteDetail,
        refTable,
        formModel,
        QuoteHistoryDrawerRef,
        MODULE,
        // handleHeaderAction,
        // handleEditSuccess,
        handleDesEditSuccess,
        handleHeaderAction,
        t,
        request,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
