/*
 * @Author: WGL
 * @Date: 2023-05-12 14:51:02
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-26 09:35:18
 * @Description: 
 */
import {
  ROLE_VISIBLE_RANGE,
  invoiceInfoConfig,
  CURRENCY
} from '@/config/variable.config'
import { version } from '@/config/net.config'
import { handleClipboard } from '@/utils/clipboard'
import { ElLoading, ElNotification } from 'element-plus'
import store from '@/store'
import { t } from '@/utils/i18n'
import request from '@/utils/request'
import { download } from '@/common/js/utils'
import { isFunction } from "lodash-es";
import VabPrint from '@/extra/VabPrint'
import dayjs from 'dayjs'
/**
 * 获取库存文字颜色
 * @param {*} num 数量
 * @param {*} stock 库存
 * @param {*} limit 预警值
 * @returns 
 */
export function getStockTextType(num, stock, limit = 0) {
  if (num) {
    const remain = stock - num
    if (remain <= 0) {
      return 'danger'
    } else if (remain <= limit) {
      return 'warning'
    } else {
      return 'primary'
    }
  } else {
    if (stock <= limit) {
      return 'warning'
    } else {
      return 'primary'
    }
  }
}

/**
 * 获取用户的数据权限
 */
export function getRoleDataAuthority() {
  const userInfo = store.getters['user/userInfo']
  let result = []
  for (const [key, val] of Object.entries(ROLE_VISIBLE_RANGE)) {
    if (+key <= userInfo.visible_range) {
      result.push({
        title: val,
        id: +key
      })
    }
  }
  return result
}

/**
 * 复制发票信息
 * @param {*} item 
 * @param {*} event 
 */
export function handleCopyInvoice(item, event) {
  console.log('event :>> ', event)
  const text = invoiceInfoConfig
    .map((text) => {
      if (text.key === 'currency') {
        return `${text.title}：${CURRENCY[item[text.key]] || '-'}`
      }
      return `${text.title}：${item[text.key] || '-'}`
    })
    .join('\n')
  handleClipboard(text, event, false)

}

/**
 * 处理多语言布局
 */
export function handleLanguageLayout() {
  let result = {}
  let labelPosition = 'left'
  const leftArr = ['zh_CN', 'zh_TW']
  const language = LS.get('language') || 'zh_CN'
  if (!leftArr.includes(language)) {
    labelPosition = 'top'
    result.labelPosition = labelPosition
  }
  return result
}

/**
 * 处理支持的币种
 * @returns 
 */
export function handleSupportCurrency() {
  const supportCurrency = store?.getters['sys/sysConfigSetting']?.support_currency || []
  const defaultCurrency = store?.getters['sys/sysConfigSetting']?.default_currency || 'CNY'
  const result = {}
  supportCurrency.forEach(item => {
    result[item] = CURRENCY[item]
  });
  return { supportCurrency: result, defaultCurrency }
}

/**
 * 转换货币
 * @param {*} currency 币种
 * @param {*} amount 金额
 * @param {*} targetCurrency 目标币种
 */
export function exchangeCurrency(currency, amount, targetCurrency) {
  if (currency === targetCurrency || !amount) {
    return amount
  } else {
    const currencyToCurrency = `${currency}-${targetCurrency}`
    const rate = store?.getters['sys/sysConfigSetting'].exchange_rate[currencyToCurrency]
    return amount * rate
  }
}

/**
 * 异步文件导出
 * @param {*} url 
 * @param {*} data 
 */
export function handleAsynExportFiles(url, data) {
  const asynExportFileList = store.getters['sys/asynExportFileList']
  store.commit('sys/setAsynExportFileList', [
    {
      url,
      ...data,
    },
    ...asynExportFileList,
  ])
}

/**
 * 异步文件导出请求
 * @param {*} url 
 * @param {*} params 
 * @param {*} filename 
 */
export function handleAsynExportFileReq(url, params, filename = '文件', callback) {
  const loadingExport = ElLoading.service({
    lock: true,
    fullscreen: true,
    text: t("正在下载中") || "正在下载中",
    background: "rgba(255, 255, 255, 0.7)",
  });

  request({
    url: url,
    method: 'get',
    params: params,
  }).then((data) => {
    loadingExport.close()
    callback?.(data)
    if (data && data?.code === 10020) {
      const tips = t('导出数据过多，已添加至异步导出队列生成')
      ElNotification({
        title: 'Success',
        message: tips,
        type: 'success',
      })
      // ElMessage.warning(t('导出数据过多，已添加至异步导出队列生成'))
      handleAsynExportFiles(url, data)
      return
    }
    download(data.http_path, filename, '')
  })
}

/**
 * 获取table的行合并
 * @param {*} key 
 * @param {*} list 
 */
export function handleGetRowSpan(key = 'id', list = []) {
  const spanArr = []
  let position = 0
  list.forEach((item, index) => {
    if (index === 0) {
      spanArr.push(1)
      position = 0
    } else {
      if (
        list[index][key] ===
        list[index - 1][key]
      ) {
        spanArr[position] += 1
        spanArr.push(0)
      } else {
        spanArr.push(1)
        position = index
      }
    }
  })
  return spanArr
}

/**
 * 合并的行
 * @param {*} columnIndex 
 * @param {*} rowIndex 
 * @param {*} spanArr 
 * @param {*} mergeIndex 合并的行索引
 * @returns 
 */

export function getArraySpanMethod(columnIndex, rowIndex, spanArr = [], mergeIndex = []) {
  if (mergeIndex.includes(columnIndex)) {
    const _row = spanArr[rowIndex]
    const _col = _row > 0 ? 1 : 0
    return {
      rowspan: _row,
      colspan: _col,
    }
  }
}

export function pureFunction(asyncFn) {
  // 返回一个新的函数，接受纯函数的参数
  return async function (...args) {
    // 等待异步操作完成
    const data = await asyncFn(...args);
    // 对异步操作的结果进行处理
    console.log('Data:', data);
    // 返回处理后的结果
    return data;
  };
}

// 比较版本号
export function compareVersion(v) {
  if (v) {
    if (version != v) {
      setTimeout(() => {
        SS.set('localDict', {})
        windowReload()
      }, 100)
    }
  }
}
/**
 * 处理请求选项的函数。
 * @param apiConfig - API配置对象，默认为空对象。用于配置请求的各类参数。
 * @param data - 请求数据，可以是任意类型。
 * @param model - 与请求相关联的模型或类型，用于指导数据处理。
 */
export function handleRequestOptions(apiConfig = {}, data, model) {
  let requestOptions = {};
  if (apiConfig?.options) {
    if (isFunction(apiConfig.options)) {
      requestOptions = apiConfig.options(data, model);
    } else {
      requestOptions = apiConfig.options;
    }
  }
  return requestOptions;
}

/**
 * 查找formList中某一个key的index
 * @param {*} key 
 * @param {*} list 
 * @param {*} searchKey 
 */
export function getFormListIndex(key = '', list = [], searchKey = 'key') {
  if (!key) return 0
  return list.findIndex(item => item[searchKey] === key)
}

export function printImage(imageUrl) {
  if (imageUrl?.includes('.pdf')) {
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.onload = function () {
      // iframe加载完成后尝试打印
      try {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      } catch (e) {
        console.error('打印失败:', e);
        window.open(imageUrl);
      }
      // 打印后可移除iframe
      document.body.removeChild(iframe);
    };
    iframe.src = imageUrl;
    document.body.appendChild(iframe)
    return
  }
  const img = new Image()
  img.src = imageUrl
  VabPrint(img)
}

/**
 * 获取路径中的id
 * @param {*} url 
 */
export function getPathId(url = '') {
  const menusObj = store.getters['routes/menusObj']
  // console.log('menusObj :>> ', menusObj);
  return menusObj[url]?.id || ''
}

const qrCodeType = {
  product: 'p_',
  order: 'so_',
  stock: 'r_',
  outbound: 'rrc_',
  inbound: 'rrr_',
  deliveryPlan: 'dp_'
}
/**
 * 生成二维码
 * @param {*} type 类型 product, order, stock, outbound, inbound, deliveryPlan
 * @param {*} id ID
 * @returns 
 */
export function handlePageDetailQrcode(type = '', id = '') {
  if (!(type || id)) return ''
  const baseUrl = 'https://s.jinweitec.com/e/?code='
  // 产品 https://s.jinweitec.com/e/?code=p_26439
  // 订单 https://s.jinweitec.com/e/?code=so_23304
  // 仓库 https://s.jinweitec.com/e/?code=r_10003
  // 出库 https://s.jinweitec.com/e/?code=rrc_372
  // 如库 https://s.jinweitec.com/e/?code=rrr_372
  // 发货计划 https://s.jinweitec.com/e/?code=dp_291
  return baseUrl + qrCodeType[type] + id
}
// 页面重新加载
export function windowReload(time = 200) {
  let currentUrl = window.location.href;
  window.location.href = currentUrl + `?t=${new Date().getTime()}`;
  // 重新加载页面
  setTimeout(() => {
    SS.set('reloadTime', dayjs().format('YYYY-MM-DD HH:mm:ss'))
    window.location.reload()
  }, time)
}
export function getMemoryUsage(time = 5) {
  setTimeout(() => {
    if ('performance' in window && 'memory' in performance) {
      const memory = performance.memory;
      // console.log(`Total JS heap size: ${handleFileSize(memory.jsHeapSizeLimit)}`);
      // console.log(`Used JS heap size: ${handleFileSize(memory.usedJSHeapSize)}`);
      // console.log(`Total available JS heap size: ${handleFileSize(memory.totalJSHeapSize)}`);
      const reloadTime = SS.get('reloadTime')
      if (memory.usedJSHeapSize > memory.jsHeapSizeLimit * 2 / 3) {
        // 内存使用率过高，重新加载页面
        if (!reloadTime || (reloadTime && dayjs().diff(dayjs(reloadTime), 'minute') > 10)) {
          windowReload(0)
        }
      }
    } else {
      console.log('Memory API not supported in this browser.');
    }
  }, 1000 * time)
}