<!--
 * @Author: WGL
 * @Date: 2023-04-18 09:16:26
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-23 17:55:15
 * @Description: 
-->
<template>
  <div v-if="showExtraField" class="system-user-container">
    <DesHeader v-if="showHeader" :border="border" title="扩展信息" />
    <base-form
      v-model="formModel"
      :config="formConfig"
      @get-detail-success="handleGetDetailSuccess"
    />
  </div>
</template>

<script>
  import { defineComponent, reactive, ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { handleExtraAttrs } from '@/utils/index'

  export default defineComponent({
    name: 'ExtraField',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      border: { type: Boolean, default: false },
      showHeader: { type: Boolean, default: false },
    },
    setup(props) {
      console.log('props.info :>> ', props.info)
      const route = useRoute()
      const store = useStore()
      const currentPath = computed(() => {
        const { path } = route
        return path || ''
      })
      const showExtraField = ref(true)
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      const canEdit = computed(() => {
        return (
          true ||
          userMenusAuthObj.value[currentPath.value].operations.some(
            (item) => item.code === 'extraEdit'
          )
        )
      })

      const formModel = ref({ id: props?.info?.id })
      let formConfig = reactive({
        api: {
          detail: `/${props.params.module}/extra/detail`,
          update: `/${props.params.module}/extra/edit`,
          detailParams: {
            id: props?.info?.id,
          },
          detailCallBack: (data) => {
            if (F.isObject(data)) {
              let result = {
                id: props?.info?.id,
              }
              for (const val of Object.values(data)) {
                const { attr_key, attr_value } = val || {}
                if (attr_key) {
                  result[attr_key] = attr_value
                }
              }
              // data.forEach((item) => {
              //   result[attr_key] = attr_value
              // })
              return result
            }
            return data
          },
        },
        formEditParams: {
          id: props?.info?.id,
        },
        beforeReq: (data, model) => {
          console.log('data :>> ', data)
          console.log('model :>> ', model)
          return data
        },
        formListKey: true,
        formAttrs: {
          labelPosition: 'right',
          labelWidth: '100px',
        },
        formList: [],
      })

      const handleGetDetailSuccess = (data) => {
        const { formList } = handleExtraAttrs(data, '')
        if (formList.length === 0) {
          showExtraField.value = false
        }
        if (formList.length > 0 && canEdit.value) {
          formList.push({
            type: 'submit',
            submit: {
              title: '确定',
              attrs: {
                type: 'primary',
              },
            },
            reset: {
              title: '重置',
            },
          })
          formConfig.formList = formList
        }
      }

      return {
        formConfig,
        handleGetDetailSuccess,
        formModel,
        showExtraField,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
