<!--
 * @Author: WGL
 * @Date: 2023-06-19 11:38:40
 * @LastEditors: WGL
 * @LastEditTime: 2024-02-18 15:13:23
 * @Description:
-->
<template>
  <div class="system-user-container fx wh">
    <FollowUpSummary
      v-if="params.showStory"
      class="ex3"
      :info="info"
      :params="params"
    />
    <div class="ex2 fv" :class="[params.showStory ? 'ml10' : '']">
      <div class="fxmj">
        <des-header :title="t('跟进信息')" />
        <el-button size="small" type="primary" @click="add()">
          {{ t('详细跟进') }}
        </el-button>
      </div>
      <!-- {{ t('快捷跟进信息') }} -->
      <div class="bbor mb10">
        <el-input
          v-model="formModel.content"
          maxlength="1000"
          :placeholder="t('请填写跟进内容')"
          :rows="3"
          show-word-limit
          type="textarea"
        />
        <div class="ptb10 fxmj">
          <div class="g3 fxm ex">
            <upload-img
              class="ex mw0"
              :imgs="formModel.imgs"
              @change="handleChangeImg"
            />
            <el-date-picker
              v-model="formModel.next_follow_time"
              class="ml10 w180"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD hh:mm:ss"
              :placeholder="t('下次跟进时间')"
              type="datetime"
              value-format="YYYY-MM-DD hh:mm:ss"
            />
          </div>
          <el-button size="small" type="primary" @click="handleAddFollow">
            {{ t('发布') }}
          </el-button>
        </div>
      </div>
      <div v-loading="loading" class="ex mh0">
        <el-empty
          v-if="show && list.length == 0"
          :description="t('暂无数据')"
        />
        <ul
          v-else
          v-infinite-scroll="load"
          class="infinite-list wh"
          infinite-scroll-distance="10"
          :infinite-scroll-immediate="false"
        >
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in list"
              :key="index"
              placement="top"
              size="large"
              :timestamp="item.update_time.substr(0, 10)"
              type="primary"
            >
              <el-card :body-style="{ padding: '10px' }" class="followItem">
                <div class="top">
                  <div class="fx">
                    <el-avatar :src="item?.admin_info?.avatar" />
                    <div class="ml10 fxj fv ex ptb2">
                      <div class="adminName">{{ item?.admin_info?.name }}</div>
                      <!-- <div class="updateTime">
                        {{ item?.update_time }}
                      </div> -->
                      <div class="g6 fs12">
                        {{ t('下次跟进') }}：{{ item?.next_follow_time || '-' }}
                      </div>
                    </div>
                  </div>
                  <div class="operation">
                    <el-button
                      size="small"
                      text
                      type="primary"
                      @click="edit(item)"
                    >
                      {{ t('编辑') }}
                    </el-button>
                    <el-button
                      size="small"
                      text
                      type="danger"
                      @click="del(item)"
                    >
                      {{ t('删除') }}
                    </el-button>
                  </div>
                </div>
                <div class="content">
                  <div v-for="(item1, index1) in item.file_list" :key="index1">
                    <el-tag style="margin: 0 10px 10px 0" type="info">
                      {{ t('附件') }}
                    </el-tag>
                    <span class="cp cb tdu" @click="showPreview(item1)">
                      {{ item1.title }}
                    </span>
                  </div>
                  <!-- <h2>跟进内容:</h2> -->
                  <div class="contentCard" v-html="item.content"></div>
                  <div class="tr g6">
                    {{ item?.update_time }}
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </ul>
      </div>
    </div>
    <el-dialog
      v-model="followUser"
      append-to-body
      center
      destroy-on-close
      fullscreen
      :title="'跟进'"
    >
      <el-form
        label-position="left"
        label-width="100px"
        style="margin-top: 20px"
      >
        <el-form-item :label="t('下次联系时间')">
          <el-col :span="24">
            <el-date-picker
              v-model="next_follow_time"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD HH:mm:ss"
              type="datetime"
              value-format="YYYY-MM-DD hh:mm:ss"
            />
          </el-col>
        </el-form-item>
        <el-form-item :label="t('跟进内容')">
          <el-col :span="24">
            <jwt-editor
              v-model="followcondition"
              height="400"
              :upload-url="uploadRichTextUrl"
            />
          </el-col>
        </el-form-item>
        <el-form-item :label="t('上传附件')">
          <el-col :span="24">
            <FileUpload
              :id="info?.id"
              v-model="fileList"
              :limit="10"
              :module="info?.module || params?.module"
            />
            <!-- <JwtFileUpload
              limit="10"
              :module="params?.module"
              :translate-title="t"
              :upload-file-url="uploadFileUrl"
              @on-success="handleUploadSuccess"
              @upload-success="handleUploadFileSuccess"
            /> -->
          </el-col>
        </el-form-item>
        <el-form-item label-width="46%">
          <el-button
            v-if="operation == 'add'"
            type="primary"
            @click="handleFollowuser"
          >
            {{ t('保存') }}
          </el-button>
          <el-button v-else type="primary" @click="editFollow">
            {{ t('保存') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <Previewer ref="previewerRef" />
  </div>
</template>

<script>
  import { defineComponent, reactive, onBeforeMount, toRefs, ref } from 'vue'
  import { fillUrl } from '@/common/js/utils'
  import request from '@/utils/request'
  import { uploadRichTextUrl, baseURL } from '@/config/index'
  import FileUpload from '@/components/FileUpload'
  import { Comment } from '@element-plus/icons-vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { t } from '@/utils/i18n'
  import FollowUpSummary from './followUpSummary.vue'
  import UploadImg from './components/uploadImg.vue'
  // 表格数据

  export default defineComponent({
    name: 'FollowUpList',
    components: {
      UploadImg,
      FileUpload,
      FollowUpSummary,
    },
    props: {
      info: { require: true, type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      const MODULE = 'follow-up' // 模块
      const uploadFileUrl = baseURL + '/company/config' // 获取cos配置信息

      console.log('props.params :>> ', props.params)
      const state = reactive({
        storyInfo: props.info.story, // 模块对应的概述
        followUser: false,
        followcondition: '',
        next_follow_time: '',
        fileList: '',
        count: 10,
        list: [],
        page: 0,
        total: 0,
        per_page: 5,
        operation: '',
        follow_up_id: null,
        show: false,
        loading: false,
        loadStatus: true,
        formModel: {
          imgs: [], // 图片
          ref_id: '', // 关联的id
          next_follow_time: '', // 下次跟进时间
          content: '', // 跟进内容
          file_list: [], // 附件
          module: props?.info?.module || props?.params?.module, // 模块
        },
      })
      const previewerRef = ref(null)
      const showPreview = (row) => {
        previewerRef.value.show(row.file_path, row.extension, row.title)
      }
      const edit = (row) => {
        state.follow_up_id = row.id
        state.fileList = row.file_list
        state.next_follow_time = row.next_follow_time
        state.followcondition = row.content
        state.followUser = true
        state.operation = 'edit'
      }

      const del = (row) => {
        console.log('row :>> ', row)
        ElMessageBox.confirm('是否删除这条跟进记录?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request({
              url: `/${MODULE}/delete`,
              method: 'post',
              data: {
                id: row.id,
              },
            }).then(() => {
              reset()
            })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消操作',
            })
          })
      }

      const add = () => {
        state.fileList = []
        state.next_follow_time = null
        state.followcondition = null
        state.followUser = true
        state.operation = 'add'
      }

      const load = () => {
        state.page += 1
        if (state.page == 1) {
          state.loading = true
        }
        getFollowList()
      }

      const getFollowList = () => {
        if (!state.loadStatus) {
          return
        }
        request({
          url: `/${MODULE}/index`,
          method: 'get',
          params: {
            sort: 'id',
            order: 'desc',
            ref_id: props?.info?.id,
            per_page: state.per_page,
            page: state.page,
            module: props?.info?.module || props?.params?.module,
          },
        }).then((res) => {
          state.list = state.list.concat(res.data)
          state.total = res.total
          state.show = true
          state.loading = false
          if (state.total > 0 && state.page * state.per_page > state.total) {
            state.loadStatus = false
          }
        })
      }

      // 跟进
      const handleFollowuser = () => {
        state.fileList.forEach((item, index) => {
          if (item?.response?.data) {
            item.response.data.index = index
            state.fileList[index] = item?.response?.data
          }
        })
        request({
          url: `/${MODULE}/add`,
          method: 'post',
          data: {
            ref_id: props?.info?.id,
            next_follow_time: state.next_follow_time,
            content: state.followcondition,
            file_list: state.fileList,
            module: props?.info?.module || props?.params?.module,
          },
        }).then(() => {
          reset()
        })
      }

      const editFollow = () => {
        let fileList = []
        state.fileList.forEach((item, index) => {
          if (item?.response?.data) {
            item.response.data.index = index
            fileList[index] = item?.response?.data
          }
        })
        request({
          url: `/${MODULE}/edit`,
          method: 'post',
          data: {
            id: state.follow_up_id,
            ref_id: props?.info?.id,
            next_follow_time: state.next_follow_time,
            content: state.followcondition,
            file_list: fileList,
            module: props?.info?.module || props?.params?.module,
          },
        }).then(() => {
          reset()
        })
      }

      const reset = () => {
        state.followUser = false
        state.followcondition = ''
        state.list = []
        state.page = 0
        state.total = 0
        state.loadStatus = true
        props.info.table?.initSearch()
        load()
      }

      // 快捷跟进
      const handleAddFollow = () => {
        const imgArr = state.formModel.imgs.map((item) => {
          return `<img src="${fillUrl(item)}" alt="">`
        })
        console.log('imgArr :>> ', imgArr)
        const imgStr = imgArr.join('')
        console.log('imgStr :>> ', imgStr)
        const contentStr = imgStr
          ? state.formModel.content + `<p>${imgStr}</p>`
          : state.formModel.content
        request({
          url: `/${MODULE}/add`,
          method: 'post',
          data: {
            ref_id: props?.info?.id,
            next_follow_time: state.formModel.next_follow_time,
            content: contentStr,
            file_list: null,
            module: props?.info?.module || props?.params?.module,
          },
        }).then(() => {
          state.formModel.content = ''
          state.formModel.imgs = []
          state.formModel.next_follow_time = ''
          reset()
        })
      }

      // 禁用时间
      const disabledDate = (time) => {
        return time.getTime() < Date.now()
      }

      const handleChangeImg = (val) => {
        console.log('val :>> handleChangeImg', val)
        state.formModel.imgs = val
      }

      // 附件保存
      const handleUploadFileSuccess = (file) => {
        console.log('file :>> handleUploadFileSuccess', file)
        request({
          url: `/file/uploadFile`,
          method: 'post',
          data: {
            id: props?.info?.id,
            module: props.params.module,
            name: file.name,
            size: file.size,
            path: '/' + file.keyPath,
          },
        }).then(() => {})
      }
      onBeforeMount(async () => {
        load()
      })
      return {
        ...toRefs(state),
        fillUrl,
        handleFollowuser,
        uploadFileUrl,
        uploadRichTextUrl,
        MODULE,
        load,
        Comment,
        showPreview,
        previewerRef,
        edit,
        add,
        editFollow,
        del,
        handleAddFollow,
        disabledDate,
        handleChangeImg,
        handleUploadFileSuccess,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    // height: 75vh;
  }
  .infinite-list {
    padding: 0 10px;
    margin: 0;
    list-style: none;
    overflow: auto;
  }
  .infinite-list .infinite-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: var(--el-color-primary-light-9);
    margin: 10px;
    color: var(--el-color-primary);
  }
  .infinite-list .infinite-list-item + .list-item {
    margin-top: 10px;
  }
  .followItem {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .adminInfo {
        display: flex;
        align-items: center;
        .admin {
          margin-left: 10px;
          .adminName {
            font-size: 13px;
            color: #333;
          }
          .updateTime {
            font-size: 12px;
            color: #999;
            margin-top: 3px;
          }
        }
      }
      .operation {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #333;
      }
    }
    .content {
      margin-top: 10px;
      .contentCard {
        margin-top: 10px;
        white-space: pre;
        :deep(img) {
          max-width: 100%;
          max-height: 300px;
          p {
            margin: 0;
          }
        }
      }
    }
  }
</style>
